<template>
    <div :key="key" class="row">
        <div class="col-md-12 p-reset">
            <div class="parallax parallax--home--main animate__animated animate__bounceIn" :style="`background-image:url(${$route.meta.image})`">
                <div class="rgba-dark">
                    <h2 class="text-white display-6 fw-bold">
                        {{$route.meta.text ? $route.meta.text.toUpperCase() : ''}}
                    </h2>
                </div>
            </div>
        </div>
        <div class="col-md-12 animate__animated animate__slideInLeft">
            <h2 class="text-center mt-5 mb-5 fw-bold">
                MAESTRANZA SIMBER SAC
            </h2>
        </div>
        <div class="col-md-12">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 text-center d-flex flex-column pb-4 animate__animated animate__slideInRight">
                        <img src="../assets/quienes-somos/home.jpg" class="w-100 rounded shadow-lg" alt="">
                        <h4 class="text-m mt-3">
                            <router-link class="text-decoration-none" :to="{name:'quienes-somos'}">
                                QUIÉNES SOMOS
                            </router-link>
                        </h4>
                        <p>
                            Somos una empresa peruana dedicada al servicio de grandes y pequeñas, en el rubro de la industria en General y Metal Mecanica.
                        </p>
                    </div>
                    <div class="col-md-4 text-center d-flex flex-column pb-4 animate__animated animate__slideInRight">
                        <img src="../assets/servicios/home.jpg" class="w-100 rounded shadow-lg" alt="">
                         <h4 class="text-m mt-3">
                            <router-link class="text-decoration-none" :to="{name:'servicios'}">
                                SERVICIOS
                            </router-link>
                        </h4>
                        <p>
                           Servicios de torno, fresa, cepillo, taladro, centro mecanizado y soldadura en general.                     
                        </p>

                    </div>
                    <div class="col-md-4 text-center d-flex flex-column pb-4 animate__animated animate__slideInRight">
                        <img src="../assets/productos/home.jpg" class="w-100 rounded shadow-lg" alt="">
                         <h4 class="text-m mt-3">
                            <router-link class="text-decoration-none" :to="{name:'servicios'}">
                                PRODUCTOS
                            </router-link>
                        </h4>
                        <p>
                            Fabricación de piezas y repuestos para la industria en general, en todo tipo de materiales (aceros, naylon, bronce, fundiciones, etc.)
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 animate__animated animate__slideInRight">
            <h2 class="text-center mt-3 mb-3 fw-bold">
                PRODUCTOS
            </h2>
        </div>
        <div class="col-md-12 parallax--home">
            <div class="owl-carousel owl-theme">
                <div v-for="(imagen,index) in imagenes" :key="index">
                    <img :src="imagen" class="w-100 rounded shadow-lg" alt="">
                </div>
              
            </div>
        </div>
        <div class="col-md-12 bg-m pt-5 pb-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h5 class="display-6 fw-bold text-white">
                            Competitivos en la industria nacional
                        </h5>
                        <p class="fs-6 text-white">
                            Contamos con un personal especializado y profesional para satisfacer todas sus necesidades en forma ágil y con un excelente nivel de calidad.
                        </p>
                    </div>
                    <div class="col-md-6 d-flex flex-column align-items-center justify-content-center">
                        <a href="tel:012519036" class="btn bg-warning btn-lg">
                            CONTÁCTANOS
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>

export default {
    data:()=>({
        key:0,
        imagenes:[]
    }),
    updated(){
        
    },
    mounted(){
        this.key+= 1;
         for(let i = 1;i<=13;i++){
          this.imagenes.push(require(`@/assets/galeria/${i}.jpg`))
        }
        this.$nextTick(()=>{
            $('.owl-carousel').owlCarousel({
                items:5,
                margin:20,
                autoplay:3000,
                loop:true,
                center:true,
                dots:false,
                nav:false,
                autoHeight:false,
                responsive:{
                    0:{items:1},
                    500:{items:2},
                    800:{items:4},
                    1000:{items:6}
                }
            })
            .trigger('refresh.owl.carousel')
        });

    }
}
</script>
<style lang="scss">
    @import '../assets/scss/variables';



</style>