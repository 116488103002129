<template>
    <div class="row">
        <div class="col-md-12 p-reset">
            <div class="parallax parallax--home--main animate__animated animate__bounceIn" :style="`background-image:url(${$route.meta.image});`">
                <div class="rgba-dark">
                    <h2 class="text-white display-6 fw-bold">
                        {{$route.meta.text ? $route.meta.text.toUpperCase() : ''}}
                    </h2>
                </div>
            </div>
        </div>
        <div class="col-md-12 p-reset">
            <div class="container mt-5">
                <div class="row text-center animate__animated animate__zoomInUp">
                    <div class="col-md-6">
                        <h4 class="text-m">
                            NOSOTROS
                        </h4>
                        <p>
                            Maestranza Simber SAC, es una empresa peruana líder en la fabricación de piezas y repuestos para el sector minero, construcción y afines. Contamos con 10 años de experiencia en el mercado peruano, brindando a nuestros clientes piezas y repuestos de calidad con tecnología de punta, gracias a nuestro personal altamente calificado.
                            Asimismo, brindamos servicios de mantenimiento y reparación de sus equipos.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <img src="~@/assets/servicios/home.jpg" style="width:70%;" class="shadow-lg rounded" alt="">
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="col-md-12 p-reset text-center">
            <div class="container">
                <div class="row animate__animated animate__zoomInDown">
                    
                    <div class="col-md-12 mb-5">
                        <div class="row">
                            <div class="col-md-6 mt-5">
                                <h4 class="text-m text-center">
                                    MISIÓN
                                </h4>
                                <p>
                                    Somos una empresa metalmecánica, dedicada a fabricar piezas y repuestos de óptima calidad y precisión para la industria en general. Para ello contamos con centros mecanizados, la cual nos permite brindar garantía en nuestros productos.
                                    Fomentar la satisfacción de nuestros clientes mediante la fabricación de piezas, de acuerdo a sus requerimientos técnicos.
                                </p>
                            </div>
                            <div class="col-md-6 mt-5">
                                <h4 class="text-m text-center">
                                    VISIÓN
                                </h4>
                                <p>
                                    Consolidar a nuestra empresa, a través de mejoras y control en todos los procesos, la que nos permite la permanencia en el mercado y brindando así confianza a nuestros clientes.
                                </p>
                            </div>
                            <!--
                            <div class="col-md-12">
                                <img src="~@/assets/quienes-somos/vision.png" class="w-40 animate__animated animate__zoomInUp" alt="">
                            </div>
                            -->
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
        <div class="col-md-12 bg-m pt-5 pb-5 animate__animated animate__zoomInUp">
             <h4 class="text-white text-center mb-5">
               NUESTRAS POLÍTICAS
            </h4>
            <div class="row justify-content-center text-white">
                <div class="col-md-5">
                   <ul class="d-flex flex-column g-30">
                       
                    

                       <li class="nav-item fw-light list-style-none text-center" style="list-style:none;">
                            MAESTRANZA SIM BER S.A.C., es una empresa dedicada a brindar servicios a la Industria en el ramo metal -mecánico tales como 
                            
                            fabricación de autopartes y estructura en general
                            
                            
                            entre otros; buscando siempre ofrecerle la mejor solución al menor tiempo y mejor costo posible; cumpliendo con Estándares y Normas en Calidad, Seguridad, Salud Ocupacional y Medio Ambiente en todos sus procesos.
                        </li>

                      
                   </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){
      
    }
}
</script>
