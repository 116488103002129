<template>
    <div class="row">
        <div class="col-md-12 p-reset">
            <div class="parallax parallax--home--main animate__animated animate__bounceIn" :style="`background-image:url(${$route.meta.image});`">
                <div class="rgba-dark">
                    <h2 class="text-white display-6 fw-bold">
                        {{$route.meta.text ? $route.meta.text.toUpperCase() : ''}}
                    </h2>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="container mt-5 mb-5">
               <div class="row" id="macy">

                    <div v-for="(imagen,index) in imagenes" :key="index" data-bs-toggle="modal" :data-bs-target="`#modal-${index}-botrusia`" class="col-md-3">
                        <img :src="imagen" class="img-fluid shadow-lg rounded" alt="">
                        <div class="modal fade" :id="`modal-${index}-botrusia`">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <img :src="imagen" class="img-fluid shadow-lg w-100 rounded" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    data:()=>({
        imagenes:[]
    }),
    updated(){
        var macy = new Macy({
            container:document.getElementById('macy'),
            trueOrder:false,
            waitForImages:false,
            columns:4,
            margin:15,  
            mobileFirst:true,
            breakAt:{
                940:5,
                520:2,
                400:1,
                350:1,
                320:1,
                250:1,
                100:1
            }
        });
    },
    mounted(){

        for(let i = 1;i<=10;i++){
          this.imagenes.push(require(`@/assets/galeria/${i}.jpg`))
        }
    }
}
</script>