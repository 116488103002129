require('bootstrap/dist/css/bootstrap.min.css')
require('bootstrap/dist/js/bootstrap.bundle.min.js')
require('@fortawesome/fontawesome-free/js/all.min.js');
require('animate.css');

window.$ = require('jquery');
window.jQuery = require('jquery');

window.Macy = require('macy');

import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
require('owl.carousel');


import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : 'Home';
    next();
  });


createApp(App)
.use(router)
.mount('#app');
