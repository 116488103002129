import {createWebHashHistory,createRouter} from 'vue-router';
import Home from '@/components/Home.vue';
import QuienesSomos from '@/components/QuienesSomos.vue';
import Servicios from '@/components/Servicios.vue';
import Galeria from '@/components/Galeria.vue';
import Clientes from '@/components/Clientes.vue';
import Productos from '@/components/Productos.vue';


export default createRouter({
    history:createWebHashHistory(),
    routes:[
        {
            path:'/',
            name:'home',
            component:Home,
            meta:{
                title:'HOME',
                text:'Inicio',
                image:require(`@/assets/inicio/portada.jpg`)
            }
        },
        {
            path:'/quienes-somos',
            name:'quienes-somos',
            component:QuienesSomos,
            meta:{
                title:'Quienes Somos',
                text:'Quiénes Somos',
                image:require(`@/assets/quienes-somos/portada.jpg`)
            }
        },
        {
            path:'/servicios',
            name:'servicios',
            component:Servicios,
            meta:{
                title:'Servicios',
                text:'Servicios',
                image:require(`@/assets/servicios/portada.jpg`)
            }
        },
        {
            path:'/productos',
            name:'productos',
            component:Productos,
            meta:{
                title:'Productos',
                text:'Productos',
                image:require(`@/assets/servicios/portada.jpg`)
            }
        },
        {
            path:'/clientes',
            name:'clientes',
            component:Clientes,
            meta:{
                title:'Clientes',
                text:'Clientes',
                image:require(`@/assets/clientes/portada.jpg`)
            }
        },
        {
            path:'/galeria',
            name:'galeria',
            component:Galeria,
            meta:{
                title:'Galeria',
                text:'Galeria',
                image:require(`@/assets/galeria/portada.jpg`)
            }
        }
    ]
});
    