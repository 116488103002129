<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12 bg-m pt-4 pb-4 text-center">
				<router-link :to="{name:'home'}">
                    <img src="~@/assets/logo/logo.jpg" style="width:170px;" class="animate__animated animate__bounce img-fluid rounded" alt="Logo">
                </router-link>
			</div>
			<div class="col-md-12 p-reset">
				<nav class="navbar navbar-expand-lg navbar-light bg-s">
					<div class="container-fluid">
						
						<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
							<span class="navbar-toggler-icon"></span>
						</button>
						<div class="collapse navbar-collapse justify-content-center" id="navbarNav">
							<div class="row">
                                <div class="col-12 col-md-12 col-xl-12 col-lg-12">
                                    <ul class="navbar-nav g-20">
                                        
                                        
                                        <li v-for="(route,index) in router" :key="index" class="nav-item">
                                            <router-link class="nav-link text-white fs-6" :to="{name:route.name}">
                                                {{route.meta.text}}
                                            </router-link>
                                        </li>

                                    </ul>
                                </div>
                                <div class="col-md-7 d-block d-md-block d-lg-none d-xl-none p-reset">
                                    <img src="~@/assets/menu/1.png" class="w-100" alt="Logo">
                                </div>
                            </div>
						</div>
					</div>
				</nav>
			</div>
		</div>
	
		<router-view :redes="redes" />
        <div class="row">
            <div class="col-md-12 bg-s p-reset">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="text-white text-center fw-bold pb-2 pt-2">
                                Tenemos Buena Calidad y Puntualidad.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<div class="col-md-12 pt-5 pb-5">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-4">
                        <h5 class="fw-bold mb-4 text-m">
                            CONTÁCTANOS
                        </h5>

                        <ul class="nav flex-column g-30">
                            <li class="nav-item">
                                <h6 class="fw-bold fs-6">DIRECCIÓN:</h6>
                                Pasaje Huánuco Mz. S, Lt. 17D
                                <br>
                                5ta. Zona 
                                La Campiña - Chorillos - Lima
                            </li>
                            
                            <li>
                                <h6 class="fw-bold fs-6">TELÉFONOS:</h6>
                                <a href="tel:012519036" class="text-decoration-none text-dark">
                                    (01) 2519036
                                </a>
                            </li>
                    
                            <!--
                            <li>
                                <h6 class="fw-bold fs-6">CELULAR:</h6>
                                <a href="tel:996725937" class="text-decoration-none text-dark">996 725 937</a>
    
                            </li>
                            -->
                            <li class="mb-4">
                                <h6 class="fw-bold fs-6">CORREO:</h6>
                                
                                <a href="mailto:simbersac@hotmail.com" class="text-decoration-none text-dark">
                                    simbersac@hotmail.com
                                </a>
                                <br />
                                <a href="mailto:info@simbersac.com" class="text-decoration-none text-dark">
                                    info@simbersac.com
                                </a>
                            </li>
                            
                            <!--
                            <li>
                                <h6 class="fw-bold fs-6">HORARIO:</h6>
                                <p>
                                    Lunes a viernes 08:00 a 17:30
                                    <br>
                                    Sábado de 08:00 a 13:00
                                </p>
                            </li>
                            -->
                        </ul>

                       
                    </div>
                    <div class="col-md-6">
                         <h5 class="fw-bold text-m mb-3">
                            UBÍCANOS
                        </h5>
                        <iframe class="w-100" height="400" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1003.6004348656544!2d-76.9955774213998!3d-12.172263862819932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b963b9a4f56f%3A0x2e3fd24765b005e!2sSIMBERSAC!5e0!3m2!1ses-419!2spe!4v1650086015654!5m2!1ses-419!2spe" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    
                </div>
            </div>
        </div>
        <ul class="redes">
            <li v-for="(red,index) in redes" :style="`background-color:${red.color}`" class="animate__animated animate__zoomInDown" :key="index">
                <a :href="red.link" target="_blank">
                    <i :class="red.clase"></i>
                </a>
            </li>
        </ul>
	</div>
</template>

<script>
	export default {
		data:()=>({
            router:[],
			redes:[
                {type:"facebook",clase:'fa-brands fa-facebook-f',color:'#4267B2',link:'https://www.facebook.com/SIMBER.SAC'},
                //{type:"whatsapp",clase:'fa-brands fa-whatsapp',color:'#25D366',link:'https://wa.me/51996725937?text=Hola, estoy interesado (a)! en adquirir sus servicios'},
                {type:"phone",clase:'fa-solid fa-phone',color:'#0066CB',link:'tel:012519036'},
                {type:"tiktok",clase:'fa-brands fa-tiktok',color:'#000000',link:''}
            ]
		}),
		mounted(){
			this.router = this.$router.getRoutes();
		}
	};
</script>
<style lang="scss">

	@import './assets/scss/variables';

</style>
