<template>
    <div class="row">
        <div class="col-md-12 p-reset">
            <div class="parallax parallax--home--main animate__animated animate__bounceIn" :style="`background-image:url(${$route.meta.image});`">
                <div class="rgba-dark">
                    <h2 class="text-white display-6 fw-bold">
                        {{$route.meta.text ? $route.meta.text.toUpperCase() : ''}}
                    </h2>
                </div>
            </div>
        </div>
        <div class="col-md-12 p-reset">
            <div class="container mt-5">
                <div class="row text-center">
                    <div class="col-md-6">
                        <h2 class="text-m">
                            QUÉ OFRECEMOS
                        </h2>
                        <p>
                            Contamos con maquinarias automatizada (CNC) para trabajos en series
                            <br />
                            Maquinarias convencionales para trabajos puntuales y fabricación de estructuras en general
                            (soldadura GMAW-FCAW).

                        </p>
                    </div>
                    <div class="col-md-6">
                        <img src="~@/assets/servicios/home.jpg" style="width:70%;" class="shadow-lg rounded" alt="">
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="container" style="margin-top:20px;">
                <div class="row">
                    
                    <div v-for="(servicio,index_s) in servicios" :key="index_s" class="col-md-12 mt-5 mb-5">
                        <h2 class="text-m text-center mb-3">
                            {{servicio.titulo}}
                        </h2>
                        <div class="row">

                            <div v-for="(imagen,index_i) in servicio.imagenes" :key="index_i" data-bs-toggle="modal" :data-bs-target="`#modal-${index_s}-${index_i}-botrusia`" class="col-md-3 mt-4">
                                <img :src="imagen" class="img-fluid shadow-lg rounded" alt="">
                                <div class="modal fade" :id="`modal-${index_s}-${index_i}-botrusia`">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                {{servicio.titulo}}
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <img :src="imagen" class="img-fluid shadow-lg w-100 rounded" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>

    

                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    data:()=>({
        servicios:[
            {
                titulo:'SERVICIOS',
                imagenes:[
                    //require('@/assets/servicios/servicios-gallery/1.jpg'),
                    require('@/assets/servicios/servicios-gallery/2.jpg'),
                    require('@/assets/servicios/servicios-gallery/3.jpg'),
                    require('@/assets/servicios/servicios-gallery/4.jpg'),
                    require('@/assets/servicios/servicios-gallery/5.jpg'),
                    require('@/assets/servicios/servicios-gallery/6.jpg'),
                    require('@/assets/servicios/servicios-gallery/7.jpg'),
                    require('@/assets/servicios/servicios-gallery/8.jpg'),
                    require('@/assets/servicios/servicios-gallery/9.jpg')
                    
                ]
            }
        ]
    }),
    updated(){
    
    },
    mounted(){
      
    }
}
</script>